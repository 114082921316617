import React from "react";
import {Container} from './style'
import LogoHeader from '../../asset/img/logo-header.png'
import { Link } from 'react-router-dom'
import { i18n } from "../../translate/i18n";

export default function NotFound404(){
    return(
        <Container>
            <div>
            <Link to="/"><img src={LogoHeader} title='RoyalEagleEnsurace' /></Link>
                <h1>{i18n.t('notFound.title')}</h1>
                <Link to="/"><p>{i18n.t('notFound.subTitle')}</p></Link>
            </div>
        </Container>
    )
}