import { insuranceCarrucel } from "../../components/InsurersCarrucel/pt"
import { header } from "../../components/Header/pt"
import { informations } from "../../components/Informations/pt" 
import { insuranceProducer } from "../../components/InsuranceProducer/pt"
import { footer } from "../../components/Footer/pt"
import { health } from "../../components/HealthContent/pt"
import { life } from "../../components/LifeContent/pt"
import { travel } from "../../components/TravelContent/pt"
import { auto } from "../../components/AutoContent/pt"
import { home } from "../../components/HomeContent/pt"
import { business } from "../../components/BusinessContent/pt"
import { truck } from "../../components/TruckContent/pt"
import { question } from "../../components/QuestionContent/pt"
import { quote } from "../../components/QuoteContent/pt"
import { contact } from "../../components/ContactContent/pt" 
import { ourValues } from "../../components/OurValuesContent/pt"
import { ourPriority } from "../../components/OurPriority/pt"
import { ourTeam } from "../../components/OurTeam/pt"
import { form } from "../../components/Form/pt"
import { feedback } from "../../components/Feedback/pt"
import { formQuote } from "../../components/FormQuoteLink/pt"
import { notFound } from "../../components/NotFound404/pt"

const messages = {
    pt: {
        translations: {
            insuranceCarrucel,
            header,
            informations,
            insuranceProducer,
            footer,
            health,
            life,
            travel,
            auto,
            home,
            business,
            truck,
            question,
            quote,
            contact,
            ourValues,
            ourPriority,
            ourTeam,
            form,
            feedback,
            formQuote,
            notFound
        }
    }
}

export { messages }