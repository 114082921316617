import { insuranceCarrucel } from "../../components/InsurersCarrucel/en"
import { header } from "../../components/Header/en"
import { informations } from "../../components/Informations/en"
import { insuranceProducer } from "../../components/InsuranceProducer/en"
import { footer } from "../../components/Footer/en"
import { health } from "../../components/HealthContent/en"
import { life } from "../../components/LifeContent/en"
import { travel } from "../../components/TravelContent/en"
import { auto } from "../../components/AutoContent/en"
import { home } from "../../components/HomeContent/en"
import { business } from "../../components/BusinessContent/en"
import { truck } from "../../components/TruckContent/en"
import { question } from "../../components/QuestionContent/en"
import { quote } from "../../components/QuoteContent/en"
import { contact } from "../../components/ContactContent/en"
import { ourValues } from "../../components/OurValuesContent/en"
import { ourPriority } from "../../components/OurPriority/en"
import { ourTeam } from "../../components/OurTeam/en"
import { form } from "../../components/Form/en"
import { feedback } from "../../components/Feedback/en"
import { formQuote } from "../../components/FormQuoteLink/en"
import { notFound } from "../../components/NotFound404/en"

const messages = {
    en: {
        translations: {
            insuranceCarrucel,
            header,
            informations,
            insuranceProducer,
            footer,
            health,
            life,
            travel,
            auto,
            home,
            business,
            truck,
            question,
            quote,
            contact,
            ourValues,
            ourPriority,
            ourTeam,
            form,
            feedback,
            formQuote,
            notFound
        }
    }
}

export { messages }