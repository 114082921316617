import styled from "styled-components";

export const SeeMore = styled.div`

    width: 270px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

`

export const SideListTitle = styled.div`

    margin-bottom: 32px;

    & h3{
        color: var(--blue-900);
        font-size: var(--text-medium);
        font-weight: var(--text-bold);
    }

`

export const BlogMiniCard = styled.div`

   border-bottom: 1px solid var(--blue-400);
   padding-bottom: 32px;
   margin-bottom: 32px;

`

export const CardMiniTitle = styled.div`

    margin-bottom: 12px;

& h3{
        color: var(--blue-900);
        font-size: var(--text-small);
        font-weight: var(--text-bold);
    }

`

export const CardDateCategory = styled.div`

    margin-bottom: 12px;

    & p{
        color: var(--blue-400);
        size: var(--text-xsmall);
    }

`

export const CardTitle = styled.div`

    margin-bottom: 12px;

    & h3{
        color: var(--blue-900);
        font-size: var(--text-medium);
        font-weight: var(--text-bold);
    }

`

export const CardResume = styled.div`

    & p{
        text-align: justify;
    }

`




