export const footer = {
    footerMenu: {
        services: 'Services',
        communQuestions: 'Common Questions',
        quote: 'Quote',
        about: 'About',
        contact: 'Contact',
        blog: 'Blog'
    },
    footerContact: {
        phone: '+1(346) 351-9372',
        phoneLink: 'https://wa.me/13463519372',
    },
    royaleagle: 'We take care, in a personalized way, of all the details so that you obtain health coverage that fits your budget and meets your needs.',
    copyright: '© 2023 Royal Eagle Insurace is Proudly Powered by Rafael Lima'
}