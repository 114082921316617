import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom'
import {GlobalStyles} from './asset/style/GlobalStyle'
import About from './page/About';
import Auto from './page/Auto';
import CommonQuestion from './page/CommonQuestions';
import Contact from './page/Contact';
import Main from './page/Main';
import NotFound from './page/NotFound';
import Quote from './page/Quote';
import Truck from './page/Truck';
import Home from './page/Home';
import Travel from './page/Travel';
import Life from './page/Life';
import Health from './page/Health';
import Business from './page/Business';
import LandPage from './page/LandPage';
import Blog from './page/Blog'
import Post from './page/Post'
import QuoteLink from './page/QuoteLink';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <NotFound />
  },
  {
    path: '/commonquestions',
    element: <CommonQuestion />
  },
  {
    path: '/quote',
    element: <Quote />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/auto',
    element: <Auto />
  },
  {
    path: '/truck',
    element: <Truck />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/travel',
    element: <Travel />
  },
  {
    path: '/life',
    element: <Life />
  }
  ,
  {
    path: '/health',
    element: <Health/>
  },
  {
    path: '/business',
    element: <Business />
  },
  {
    path: '/landpage',
    element: <LandPage />
  },
  {
    path: '/blog',
    element: <Blog />
  },
  {
    path: '/blog/post/:postLink',
    element: <Post />,
    errorElement: <NotFound />

  },
  {
    path: '/quotelink',
    element: <QuoteLink />
  },
  {
    path: '/notfound',
    element: <NotFound />
  },
  {
    path: '*',
    element: <Navigate to="/notfound" />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <GlobalStyles />
  </React.StrictMode>
);