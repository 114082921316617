export const header = {
    menuContact: {
        phone: '+1(346) 351-9372',
        phoneLink: 'https://wa.me/13463519372',
    },
    menu: {
        service: 'Service',
        commonQuestions: 'Common Questions',
        quote: 'Quote',
        about: 'About',
        contact: 'Contact',
        blog: 'Blog'
    },
    bannerTitle: {
        normalText: 'We take pride in taking care of your insurance needs and that extends to ',
        boldText: 'ALL TYPES OF INSURANCE'
    },
    bannerSubTitle: {
        normalText: 'Keep everything under control ',
        boldText: 'Royal Eagle Insurance'
    },
    insuranceCard: {
        health: 'Having health insurance can provide you with financial protection against high medical expenses and access to a wider range of medical services.',
        life: 'Life insurance is a type of insurance that offers financial protection to your loved ones in the event of your death. It pays a lump sum of money to its beneficiaries to help cover expenses such as funeral costs, debts and living expenses.',
        travel: 'We can help you find a suitable travel insurance policy that meets your needs. You can compare all your travel insurance options, get coverage online, and travel worry-free.',
        auto: 'At Royal Eagle Insurance, we understand that there is no one size fits all car insurance coverage. We work one-on-one with our customers to ensure they get the coverage they need most affordably and securely.',
        home: 'Your home insurance can help protect you from the high costs of repairing or replacing your home after an accident on your property, avoiding financial consequences if your home is, for example, damaged by a fire or other covered event. Home insurance helps you make the necessary repairs to restore your home\'s value.',
        business: 'Business insurance refers to the various lines of coverage that businesses may need to adequately protect themselves against the risks they face. We will help you identify the types of coverage and appropriate amounts of business insurance you need to ensure the safety of your business.',
        truck: 'Having the right insurance coverage is critical for any independent truck driver/owner. Without it, a single incident could put you out of business.'
    }
}