import styled from "styled-components";
import Background from '../../asset/img/background-notfound.jpg'

export const Container = styled.section`

    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    background: url(${Background}) no-repeat center;
    background-size: cover;

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;

        @media screen and (max-width: 480px){
            padding-left: 20px;
            padding-right: 20px;
        }

    }

    h1{

        font-size: var(--text-medium);
        font-weight: bold;
        color: white;

    }

    a{
        display: flex;
        justify-content: center;
        
        &:hover p{
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    p{
        font-size: var(--text-small);
        font-weight: lighter;
        color: white;
        border: 1px solid white;
        border-radius: 12px 0px 12px 0px;
        padding: 8px 12px 8px 12px;
        transition: linear 0.2s background;
        @media screen and (max-width: 480px){
            width: 60%;
            text-align: center;
        }
    }

`