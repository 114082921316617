import React from "react";
import { useParams, Navigate } from "react-router-dom";
import getPost from '../../asset/blog/post'

import { Post, PostContainer, Title, BlogSpace, PostInfo, PostImage, PostDateCategory, PostText, PostTitle} from './style'

import IconBlog from '../../asset/img/icon-title-blog.svg';
import BlogSeeMore from "../BlogSeeMore";

export default function PostContent(){

    const {postLink} = useParams()
    const postResult = getPost(postLink)

        if (!postResult) {
            return <Navigate to="*" />
        }

   
    function titleBold(text){
        const frases = text.split('*')        
        return frases.map(frase => {
            return frase.includes('[') ? <strong>{frase.replace('[','').replace(']','')}</strong> : <>{frase}</>           
        })
    }

    return(
        <Post>
            <PostContainer>

                <Title>
                    <img src={IconBlog} alt="Blog" />
                    <h1>Blog</h1>
                </Title>

                <BlogSpace>
                    
                    <PostInfo>

                        <PostImage src={postResult.image} />
                        
                        <PostDateCategory>
                            <p>{postResult.date} | {postResult.category}</p>
                        </PostDateCategory>
                        <PostTitle>
                            <h3>{postResult.title}</h3>
                        </PostTitle>
                        <PostText>
                            <p>
                                {titleBold(postResult.text)}
                            </p>
                        </PostText>

                    </PostInfo>

                    <BlogSeeMore />

                </BlogSpace>

            </PostContainer>
        </Post>
    )
}