export const footer = {
    footerMenu: {
        services: 'Serviços',
        communQuestions: 'Perguntas Frequentes',
        quote: 'Cotação',
        about: 'Sobre',
        contact: 'Contato',
        blog: 'Blog'
    },
    footerContact: {
        phone: '+1(346) 351-9858',
        phoneLink: 'https://wa.me/13463519858',
    },
    royaleagle: 'Nós cuidamos, de forma personalizada, de todos os detalhes para que você obtenha uma cobertura de saúde que se encaixe no seu orçamento e atenda às suas necessidades.',
    copyright: '© 2023 Royal Eagle Insurance é orgulhosamente desenvolvido por Rafael Lima',
}