import React from "react";

import { FoundedCompany, ImageContent, OurTeamContainer, OurTeamContent, Profile, ProfileEducation, ProfileEducationContent, ProfileImage, ProfileInfo, TeamProfile } from './style'

import { i18n } from "../../translate/i18n";

import EdimaraTeam from '../../asset/img/img-team-edimara.png'
import PatriciaTeam from '../../asset/img/img-team-patricia.png'
import FernandaTeam from '../../asset/img/img-team-fernanda.png'
import EnzoTeam from '../../asset/img/img-team-enzo.png'

export default function OurTeam(){
    return(
        
        <OurTeamContainer>

            <OurTeamContent>

                <FoundedCompany>

                    <p>
                        {i18n.t('ourTeam.foundedCompany.text')}
                    </p>

                    <h2>
                        {i18n.t('ourTeam.foundedCompany.title')}
                    </h2>

                </FoundedCompany>

                <hr />

                <TeamProfile>

                    <Profile>
                        <ProfileInfo>
                            <div>
                                <h2>{i18n.t('ourTeam.team.edimara.title')}</h2>
                                <h4>{i18n.t('ourTeam.team.edimara.subTitle')}</h4>
                            </div>

                            <p>
                                {i18n.t('ourTeam.team.edimara.text1')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.edimara.text2')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.edimara.text3')}
                            </p>
                        </ProfileInfo>
                        <ProfileImage>
                            <ImageContent>
                                <img src={EdimaraTeam} alt={i18n.t('ourTeam.team.edimara.title')} />
                            </ImageContent>
                        </ProfileImage>
                    </Profile>

                    <ProfileEducation>
                        <div>
                            <ProfileEducationContent>
                                <div>
                                    <h2>{i18n.t('ourTeam.team.edimara.certifications.number')}</h2>
                                    <h4>
                                        {i18n.t('ourTeam.team.edimara.certifications.title')}
                                    </h4>
                                </div>
                                <ul>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.1')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.2')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.3')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.4')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.5')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.6')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.7')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.certifications.list.8')}</li>
                                </ul>
                            </ProfileEducationContent>
                            <ProfileEducationContent>
                                <div>
                                    <h2>{i18n.t('ourTeam.team.edimara.bachelors.number')}</h2>
                                    <h4>
                                        {i18n.t('ourTeam.team.edimara.bachelors.title')}
                                    </h4>
                                </div>
                                <ul>
                                    <li>{i18n.t('ourTeam.team.edimara.bachelors.list.1')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.bachelors.list.2')}</li>
                                </ul>
                            </ProfileEducationContent>
                            <ProfileEducationContent>
                                <div>
                                    <h2>{i18n.t('ourTeam.team.edimara.postgraduate.number')}</h2>
                                    <h4>
                                        {i18n.t('ourTeam.team.edimara.postgraduate.title')}
                                    </h4>
                                </div>
                                <ul>
                                    <li>{i18n.t('ourTeam.team.edimara.postgraduate.list.1')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.postgraduate.list.2')}</li>
                                    <li>{i18n.t('ourTeam.team.edimara.postgraduate.list.3')}</li>
                                </ul>
                            </ProfileEducationContent>
                        </div>
                    </ProfileEducation>
                    
                </TeamProfile>
                <TeamProfile>

                    <Profile left={true}>
                        <ProfileImage>
                            <ImageContent left={true}>
                                <img src={PatriciaTeam} alt={i18n.t('ourTeam.team.patricia.title')} />
                            </ImageContent>
                        </ProfileImage>

                        <ProfileInfo left={true}>
                            <div>
                                <h2>{i18n.t('ourTeam.team.patricia.title')}</h2>
                                <h4>{i18n.t('ourTeam.team.patricia.subTitle')}</h4>
                            </div>

                            <p>
                                {i18n.t('ourTeam.team.patricia.text1')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.patricia.text2')}
                            </p>
                        </ProfileInfo>
                    </Profile>

                    <ProfileEducation left={true}>
                        <div>
                            <ProfileEducationContent>
                                <div>
                                    <h2>{i18n.t('ourTeam.team.patricia.certifications.number')}</h2>
                                    <h4>
                                        {i18n.t('ourTeam.team.patricia.certifications.title')}
                                    </h4>
                                </div>
                                <ul>
                                    <li>{i18n.t('ourTeam.team.patricia.certifications.list.1')}</li>
                                </ul>
                            </ProfileEducationContent>
                            <ProfileEducationContent>
                                <div>
                                    <h2>{i18n.t('ourTeam.team.patricia.bachelors.number')}</h2>
                                    <h4>
                                        {i18n.t('ourTeam.team.patricia.bachelors.title')}
                                    </h4>
                                </div>
                                <ul>
                                    <li>{i18n.t('ourTeam.team.patricia.bachelors.list.1')}</li>
                                    <li>{i18n.t('ourTeam.team.patricia.bachelors.list.2')}</li>
                                </ul>
                            </ProfileEducationContent>
                        </div>
                    </ProfileEducation>
                    
                </TeamProfile>
                <TeamProfile>

                    <Profile>

                        <ProfileInfo>
                            <div>
                                <h2>{i18n.t('ourTeam.team.fernanda.title')}</h2>
                                <h4>{i18n.t('ourTeam.team.fernanda.subTitle')}</h4>
                            </div>

                            <p>
                                {i18n.t('ourTeam.team.fernanda.text1')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.fernanda.text2')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.fernanda.text3')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.fernanda.text4')}
                            </p>
                        </ProfileInfo>

                        <ProfileImage>
                            <ImageContent>
                                <img src={FernandaTeam} alt={i18n.t('ourTeam.team.fernanda.title')} />
                            </ImageContent>
                        </ProfileImage>
                        
                    </Profile>
                    
                </TeamProfile>
                <TeamProfile>

                    <Profile left={true}>
                        <ProfileImage>
                            <ImageContent left={true}>
                                <img src={EnzoTeam} alt={i18n.t('ourTeam.team.enzo.title')} />
                            </ImageContent>
                        </ProfileImage>

                        <ProfileInfo left={true}>
                            <div>
                                <h2>{i18n.t('ourTeam.team.enzo.title')}</h2>
                                <h4>{i18n.t('ourTeam.team.enzo.subTitle')}</h4>
                            </div>

                            <p>
                                {i18n.t('ourTeam.team.enzo.text1')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.enzo.text2')}
                            </p>
                            <p>
                                {i18n.t('ourTeam.team.enzo.text3')}
                            </p>
                        </ProfileInfo>
                    </Profile>
                    
                </TeamProfile>

            </OurTeamContent>

        </OurTeamContainer>

    )
}