import styled from "styled-components";
import BackgroundPurple from '../../asset/img/background-purple.svg'

export const Blog = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: url(${BackgroundPurple}) no-repeat left top;

    @media screen and (max-width: 480px){
        margin-top: -60px;
    }
`

export const BlogContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }

`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
    }

`
export const BlogSpace = styled.div`

    display: flex;
    margin-bottom: 120px;

    @media screen and (max-width: 480px){
        flex-direction: column;
        gap: 80px;
    }

`

export const BlogMainList = styled.div`

    width: 900px;
    display: flex;
    gap: 80px;
    flex-wrap: wrap;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        a{
            width: 100%;
        }
    }

`

export const BlogMainListContainer = styled.div`

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px){
        width: 100%;
    }

`

export const Pagination = styled.div`

    padding-top: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    & p{
        margin-left: 20px;
        margin-right: 20px;
    }

`

export const LeftButton = styled.button`

    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid;
    color: var(--white);
    font-weight: var(--text-light);
    background: linear-gradient(76.81deg, #DE57A8 -64.64%, #EF4040 68.94%, #FFB0B0 115.78%);
    transition: linear 0.2s;

    &:hover{
        background: linear-gradient(white, white);
        color: #EF4040;
        border: 1px solid #EF4040;
    }
`

export const RightButton = styled.button`

    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid;
    color: var(--white);
    font-weight: var(--text-light);
    background: linear-gradient(76.81deg, #DE57A8 -64.64%, #EF4040 68.94%, #FFB0B0 115.78%);
    transition: linear 0.2s;
    

    &:hover{
        background: linear-gradient(white, white);
        color: #EF4040;
        border: 1px solid #EF4040;
    }
`

export const BlogCard = styled.div`

    width: 370px;
    
    @media screen and (max-width: 480px){
        width: 100%;
    }
`

export const CardImgContent = styled.div`

    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

`

export const CardImg = styled.img`

    border-radius: 12px;

`

export const CardIconContent = styled.div`

    width: 120px;
    height: 158px;
    border-radius: 12px 0 0 12px;
    background-color: rgba(180, 4, 9, 0.6);
    margin-top: -158px;
    display: flex;
    justify-content: center;
    align-items: center;

`

export const CardIcon = styled.img`



`

export const CardDateCategory = styled.div`

    margin-bottom: 12px;

    & p{
        color: var(--blue-400);
        size: var(--text-xsmall);
    }

`

export const CardTitle = styled.div`

    margin-bottom: 12px;

    & h3{
        color: var(--blue-900);
        font-size: var(--text-medium);
        font-weight: var(--text-bold);
    }

`

export const CardResume = styled.div`

    & p{
        text-align: justify;
    }

`

export const BlogSideList = styled.div`

    width: 270px;

`

export const SideListTitle = styled.div`

    margin-bottom: 32px;

    & h3{
        color: var(--blue-900);
        font-size: var(--text-medium);
        font-weight: var(--text-bold);
    }

`

export const BlogMiniCard = styled.div`

   border-bottom: 1px solid var(--blue-400);
   padding-bottom: 32px;
   margin-bottom: 32px;

`

export const CardMiniTitle = styled.div`

    margin-bottom: 12px;

& h3{
        color: var(--blue-900);
        font-size: var(--text-small);
        font-weight: var(--text-bold);
    }

`



