import styled from "styled-components"
import BackgroundTeam from '../../asset/img/background-team.svg'


export const OurTeamContainer = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;

`
export const OurTeamContent = styled.div`

    width: var(--container-width);
    margin-top: 120px;
    margin-bottom: 120px;

    & hr{
        margin-left: 100px;
        margin-right: 100px;
        border: 1px solid   var(--blue-100);
        position: relative;
        z-index: 2;

    }

    @media screen and (max-width: 480px){
        width: 100%;
    }

`
export const FoundedCompany = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 130px;
    margin-bottom: 120px;
    position: relative;
    z-index: 2;


    & p{
        width: 370px;
        margin-left: 100px;
    }

    & h2{
        width: 570px;
        font-size: 55px;
        font-weight: var(--text-bold);
        color: var(--blue-900);
        text-align: justify;
    }

    @media screen and (max-width: 480px){
        flex-direction: column;
        gap: 80px;
        padding-left: 20px;
        padding-right: 20px;

        & p{
            width: 100%;
            margin-left: 0px;
            text-align: justify;
        }

        & h2{
            width: 100%;
            font-size: 40px;
            text-align: center;
        }
    }

`
export const TeamProfile = styled.div`

    margin-top: 140px;
    

`

export const Profile = styled.div`

    width: var(--container-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 80px;    
    background: url(${BackgroundTeam}) no-repeat ${props => props.left ? ('right') : ('left')} top;
    
    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;
        background: url(${BackgroundTeam}) no-repeat ${props => props.left ? ('right') : ('left')} 500px;
        background-size: 100%;
    }

`

export const ProfileInfo = styled.div`

    ${props => props.left ? ('margin-right: 100px') : ('margin-left: 100px')};
    

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-1000);
    }
    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-normal);
        color: var(--blue-700);
        margin-bottom: 40px;
        width: 570px;
    }

    & p{
        width: 570px;
        text-align: justify;
        margin-bottom: 24px;

        &:nth-last-child(1){
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 480px){

        ${props => props.left ? ('margin-right: 0px') : ('margin-left: 0px')};

        order:2;
        z-index: 5;
        padding-left: 20px;
        padding-right: 20px;

        & h2{
            margin-top: 40px;
        }

        & p{
            width: 100%;
        }
        & h4{
            width: 100%;
        }

    }

`

export const ProfileImage = styled.div`
    @media screen and (max-width: 480px){
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        order: 1;
        margin-bottom: 80px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        & img{
            width: 188%;
        }
    }
`

export const ImageContent = styled.div`

    width: 444px;
    margin-top: -300px;

    & img{
        
        ${props => props.left && ('margin-left: -290px;')}

    }

    @media screen and (max-width: 480px){
        width: 100%;

        & img{        
            ${props => props.left && ('margin-left: -88%;')}
        }

    }
`

export const ProfileEducation = styled.div`

    display: flex;
    justify-content: center;

    & > div{

        width: 970px;
        display: flex;
        justify-content: center;
        gap: 35px;
        position: relative;
        z-index: 2;

    }

    @media screen and (max-width: 480px){
        justify-content: flex-start;

        & > div{
            width: 100%;
            flex-direction: column;
            padding-left: 20px;
            padding-right: 20px;

        }
    }

`
export const ProfileEducationContent = styled.div`

    width: 300px;

    & > div{

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-bottom: 40px;

        & h2{
            font-size: 100px;
            font-weight: var(--text-bold);
            color: var(--red-700);
        }

        & h4{
            font-size: var(--text-small);
            font-weight: var(--text-bold);
            color: var(--blue-700);
        }
    }

    & ul{

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        
        & li{
            padding: 12px 16px;
            border-radius: 8px;
            color: var(--white);
            font-weight: var(--text-light);
            background: linear-gradient(76.81deg, #DE57A8 -64.64%, #EF4040 68.94%, #FFB0B0 115.78%);

        }
    }

    @media screen and (max-width: 480px){
        width: 100%;
        
        & > div{
            width: 100%;
            justify-content: flex;
        }

        & ul{
            width: 100%;
            justify-content: flex-start;

        }
    }
`