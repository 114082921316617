import styled from "styled-components";
import BackgroundPurple from '../../asset/img/background-purple.svg'

export const Post = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: url(${BackgroundPurple}) no-repeat left top;

    @media screen and (max-width: 480px){
        margin-top: -60px;
    }
`

export const PostContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }

`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
    }

`
export const BlogSpace = styled.div`

    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;

    @media screen and (max-width: 480px){
        flex-direction: column;
        gap: 120px
    }

`

export const PostInfo = styled.div`

    width: 820px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
`


export const PostImage = styled.img`

    width: 820px;
    margin-bottom: 12px;
    border-radius: 12px;

    @media screen and (max-width: 480px){
        width: 100%;
    }


`

export const PostDateCategory = styled.div`

margin-bottom: 12px;

& p{
    color: var(--blue-400);
    size: var(--text-xsmall);
}

`

export const PostTitle = styled.div`

margin-bottom: 24px;

& h3{
    color: var(--blue-900);
    font-size: var(--text-medium);
    font-weight: var(--text-bold);
}

`

export const PostText = styled.div`

    & p{
        text-align: justify;
        min-height: 24px;
        white-space: pre-line;

        strong{
            font-weight: bold;
        }
    }

`


