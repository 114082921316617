import React from "react";
import {Link} from 'react-router-dom'
import {FooterContainer, FooterContent, Nav, Contact, LogoFooter, FooterCopyRight, ImgSocialMedia} from './style'
import { i18n } from '../../translate/i18n'

import IconFooterMail from '../../asset/img/icon-footer-mail.svg'
import IconFooterWhatsapp from '../../asset/img/icon-footer-whatsapp.svg'
import IconFooterAddress from '../../asset/img/icon-footer-address.svg'
import IconFooterFacebook from '../../asset/img/icon-footer-facebook.svg'
import IconFooterInstagram from '../../asset/img/icon-footer-instagram.svg'
import ImgLogoFooter from '../../asset/img/logo-footer-royaleagle.png'


export default function Footer(){
    return(
        <FooterContainer>
            <FooterContent>
                <Nav>
                    <ul>
                        {/* <Link><li>{i18n.t('footer.footerMenu.services')}</li></Link> */}
                        <Link to="/blog" onClick={() => { window.scroll({ top: 0, left: 0,}) }}><li>{i18n.t('footer.footerMenu.blog')}</li></Link>
                        <Link to="/commonquestions" onClick={() => { window.scroll({ top: 0, left: 0,}) }}><li>{i18n.t('footer.footerMenu.communQuestions')}</li></Link>
                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}><li>{i18n.t('footer.footerMenu.quote')}</li></Link>
                        <Link to="/about" onClick={() => { window.scroll({ top: 0, left: 0,}) }}><li>{i18n.t('footer.footerMenu.about')}</li></Link>
                    </ul>
                </Nav>
                <Contact>

                    <h4>{i18n.t('footer.footerMenu.contact')}</h4>

                    <ul>
                        <li>
                            <Link to="mailto:edimara@royaleagleinsurance.com">
                                <div>
                                    <img src={IconFooterMail} alt="" />
                                </div>
                                <p>edimara@royaleagleinsurance.com</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={i18n.t('footer.footerContact.phoneLink')} target="blank">
                                <div>
                                    <img src={IconFooterWhatsapp} alt="" />
                                </div>
                                <p>{i18n.t('footer.footerContact.phone')}</p>
                            </Link>
                        </li>
                        <li>
                            <Link>
                                <div>
                                    <img src={IconFooterAddress} alt="" />
                                </div>
                                <p>1040 Schlipf Rd - Suite 129 <br /> Katy, Tx 77493</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.facebook.com/Royal-Eagle-Insurance-101503306195835" target="blank"><ImgSocialMedia src={IconFooterFacebook} alt="" /></Link>
                            <Link to="https://www.instagram.com/royaleagleins/" target="blank"><ImgSocialMedia src={IconFooterInstagram} alt="" /></Link>
                        </li>
                    </ul>

                </Contact>
                <LogoFooter>

                    <Link to="/"  onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                        <img src={ImgLogoFooter} alt="Logo Royal Eagle Insurance" />
                    </Link>

                    <p>
                        {i18n.t('footer.royaleagle')}
                    </p>

                </LogoFooter>
            </FooterContent>
               
            <FooterCopyRight>  
                <div>
                    <p>
                        {i18n.t('footer.copyright')}
                    </p>
                </div>
            </FooterCopyRight>
        </FooterContainer>
    )
}
